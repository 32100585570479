<template>
  <b-nav-item-dropdown
    left
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p
          class="user-name font-weight-bolder mb-0"
        >
          <feather-icon
            size="13"
            icon="UserCheckIcon"
          />
          {{ accountShortName }}
        </p>
      </div>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      accountShortName: 'wallet/getAccountShortName',
    }),
  },
  methods: {
    ...mapActions({
      setAccount: 'wallet/setAccount',
      setLogout: 'wallet/setLogout',
    }),
    logout() {
      this.setAccount('')
      this.setLogout(1)
    },
  },
}
</script>

<style scoped>

</style>
