export default [
  {
    title: 'Balancer',
    icon: 'PieChartIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Wallet',
        icon: 'UserIcon',
        route: 'balancerWallet',
      },
      // {
      //   title: 'Sub accounts',
      //   icon: 'UserPlusIcon',
      //   route: 'balancerSubAccounts',
      // },
    ],
  },
  // {
  //   title: 'Market maker',
  //   icon: 'BarChartIcon',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'Wallet',
  //       icon: 'UserIcon',
  //       route: 'marketMakerWallet',
  //     },
  //     {
  //       title: 'Sub accounts',
  //       icon: 'UserPlusIcon',
  //       route: 'marketMakerSubAccounts',
  //     },
  //   ],
  // },
]
