<template>
  <b-nav>
    <!-- balance -->
    <b-nav-item-dropdown
      :class="addClassCurrentRouteName(['balancerSubAccounts', 'balancerWallet'])"
      :text="$t('Balancer')"
      href="javascript:void(0)"
      icon="BarChartIcon"
      toggle-class="nav-link-custom"
      left
    >
      <b-dropdown-item
        :class="addClassCurrentRouteName(['balancerWallet'])"
        :to="{ path: '/balancer/wallet' }"
      >
        {{ $t('Wallet') }}
      </b-dropdown-item>
      <!-- <b-dropdown-item
        :class="addClassCurrentRouteName(['balancerSubAccounts'])"
        :to="{ path: '/balancer/sub-accounts' }"
      >
        {{ $t('Sub accounts') }}
      </b-dropdown-item> -->
    </b-nav-item-dropdown>

    <!-- market maker -->
    <!-- <b-nav-item-dropdown
      :class="addClassCurrentRouteName(['marketMakerSubAccounts', 'marketMakerWallet'])"
      :text="$t('Market maker')"
      icon="BarChartIcon"
      toggle-class="nav-link-custom"
      left
    >
      <b-dropdown-item
        :to="{ path: '/market-maker/wallet' }"
      >
        {{ $t('Wallet') }}
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ path: '/market-maker/sub-accounts' }"
      >
        {{ $t('Sub accounts') }}
      </b-dropdown-item>
    </b-nav-item-dropdown> -->
  </b-nav>
</template>

<script>
import { BNav, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNav,
    BNavItemDropdown,
    BDropdownItem,
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    addClassCurrentRouteName(tags) {
      return { strong: tags.includes(this.currentRouteName) }
    },
  },
}
</script>

<style lang="scss" scoped>
  .strong {
    font-weight: 700;
  }
</style>
