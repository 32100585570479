<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="2">
          <b-navbar-nav class="nav mb-2">
            <network />
          </b-navbar-nav>
        </b-col>
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t(`${$route.meta.pageTitle}`) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(`${item.text}`) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BNavbarNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Network from './Network.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    Network,
    BBreadcrumb,
    BBreadcrumbItem,
    BNavbarNav,
    BRow,
    BCol,
  },
}
</script>
