<template>
  <b-nav-item-dropdown>
    <template #button-content>
      <b-img
        :src="currentNick.img"
        height="32px"
        width="32px"
        :alt="currentNick.nick"
      />
      <span class="ml-50 text-body">{{ currentNick.name }}</span>
    </template>
    <b-dropdown-item
      v-for="nw in networks"
      :key="nw.nick"
      :disabled="nw.disabled"
    >
      <b-img
        :src="nw.img"
        height="32px"
        width="32px"
        :alt="nw.nick"
      />
      <span class="ml-50">{{ nw.name }}</span>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      networks: [
        {
          nick: 'ethereum',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/svg/ethereum.svg'),
          name: 'Ethereum',
          disabled: true,
        },
        {
          nick: 'polygon',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/svg/polygon.svg'),
          name: 'Polygon',
          disabled: false,
        },
        {
          nick: 'arbitrum',
          // eslint-disable-next-line global-require
          img: require('@/assets/images/svg/arbitrum.svg'),
          name: 'Arbitrum',
          disabled: true,
        },
      ],
    }
  },
  computed: {
    currentNick() {
      return this.networks.find(l => l.nick === 'polygon')
    },
  },
}
</script>
